<script setup>
import { inject, onMounted } from 'vue'
import CoursesComponent from '@/components/CoursesComponent.vue'
import FeaturesComponent from '@/components/FeaturesComponent.vue'
import TimetableComponent from '@/components/TimetableComponent.vue'

const store = inject('store')

onMounted(() => {
  store.methods.onLoad()
})

</script>

<!-- IMPORTANT: Bootstrap tables have a default margin-bottom: 1rem so if you notice extra spacing anywhere, inspect for a <table> in use -->

<template>
  <section class="bg-secondary">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6">
          <courses-component></courses-component>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <features-component></features-component>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-dark">
    <div class="container">
      <timetable-component></timetable-component>
    </div>
  </section>
</template>
