<script setup>
import { inject } from 'vue'

const store = inject('store')

</script>

<template>
    <h1 class="display-3 text-uppercase text-black mb-4">{{ store.state.hero.title }}
    </h1>
    <h3 class="display-5 text-uppercase text-muted mb-4">
        {{ store.state.hero.subtitle }}
    </h3>
</template>

<style scoped>
.bg-course {
    background: url('../assets/bg_icons.png') 0 100% repeat transparent;
}
</style>