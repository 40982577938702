<script setup>
import { inject } from 'vue'

const store = inject('store')

</script>

<template>
  <section class="">
    <div class="container">

      <h3 class="display-5 text-uppercase text-center mb-4">Maths tuition fee</h3>

      <div class="row">
        <div class="col-sm-12 col-md-6 offset-md-3">
          <div class="table-responsive">
            <table class="table table-dark table-striped table-sm text-secondary">
              <thead>
                <tr>
                  <th scope="col" class="h3 text-uppercase text-grey">Fee</th>
                  <th scope="col" class="h3 text-uppercase text-grey">Pay via net banking</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-uppercase fw-bold text-grey">Bank</td>
                  <td class="text-uppercase fw-bold text-grey">{{ store.state.fee.bankname }}</td>
                </tr>
                <tr>
                  <td class="text-uppercase fw-bold text-grey">Acc. Name</td>
                  <td class="text-uppercase fw-bold text-grey">{{ store.state.fee.accname }}</td>
                </tr>
                <tr>
                  <td class="text-uppercase fw-bold text-grey">Acc. Num</td>
                  <td class="text-uppercase fw-bold text-grey">{{ store.state.fee.accnum }}</td>
                </tr>
                <tr>
                  <td class="text-uppercase fw-bold text-grey">Sort Code</td>
                  <td class="text-uppercase fw-bold text-grey">{{ store.state.fee.sortcode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <pre>
        Leo is inviting you to a scheduled Zoom meeting.

        Topic: ConsoleLogLondon
        Time: This is a recurring meeting Meet anytime

        Join Zoom Meeting
        https://us02web.zoom.us/j/86887991572?pwd=STdHKysydVEweW1Tci83MHJpSnk3dz09

        Meeting ID: 868 8799 1572
        Passcode: 432234


      </pre> -->
      
    </div>
  </section>
</template>
