import { readonly, reactive } from 'vue'

const state = reactive({
  brand: {
    part1: 'Ex',
    part2: 'Hour',
    part3: 'UK',
    caption: 'Data Structures and Algorithms tuition',
    levels: 'Computer Science and Engineering',
    email: 'MathsHourUK@gmail.com',
    domain: '@gmail.com',
    mail_to: 'mailto:MathsHourUK@gmail.com?subject=Regarding DSA tuition...',
  },

  hero: {
    title: `
    Expert Data Structures and Algorithms Tuition for Computer Science
    `,
    subtitle: `
    Comprehensive Guidance to Excel in DSA, Tailored to Your Unique Learning Journey
    `,
  },

  courses: [
    {
      coursename: 'BSc. Engineering',
      subjects: [
        {
          title: 'Engineering Maths',
          cover: 'stroud.jpg'
        },
        {
          title: 'Engineering Maths - Advanced',
          cover: 'stroud_adv.jpg'
        },
      ],
    }
  ],

  features: {
    name: 'Unlimited live online tuition',
    firstmonth: 'Free First month',
    nocontract: 'no contract',
    nocard: 'no card details',
    noadvance: 'no advance payment',
    fee: '£10 per month',
    unlimitedlive: 'unlimited live online classes',
    duration: '45-min per class',
  },

  tutor: {
    name: 'Leo Santosh',
    pg: 'Masters - City, University of London',
    about: `
    MathsHour provides expert Maths tuition for GCSE, AS Level, A Level, and University students. Our experienced tutor offers personalised lessons designed to strengthen understanding, boost grades, and build confidence in key mathematical topics. Whether you're preparing for exams or looking to master specific areas, MathsHour is dedicated to helping students achieve their academic goals.
    `,
  },

  fee: {
    bankname: 'NatWest',
    accname: 'Santosh Singh',
    accnum: '1010 0431',
    sortcode: '60-60-15',
    paypal: 'https://www.paypal.me/MathsHour',
  },

  timetable: [
    {
      name: 'Engineering Maths',
      level: 'BSc. Engineering',
      thumbnail: 'stroud.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '9am',
      sun: '9am',
    },
    {
      name: 'Advanced Engineering Maths',
      level: 'BSc. Engineering',
      thumbnail: 'stroud_adv.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '10am',
      sun: '10am',
    }
  ],
})

const methods = {
  onLoad() {
    console.log('courses loaded')
  },
}

const getters = {

}

export default {
  state: readonly(state),
  methods,
  getters,
}